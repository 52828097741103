<template>
	<section class="section-wrapper">
		<base-heading tag="h1" :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`" v-animate="`slideLeft`"/>
		<div v-background="background" class="wrapper">
			<base-container>
				<div class="image-wrapper" v-animate="`opacity`">
					<cms-image
						v-for="(image,id) in value.gallery"
						class="image"
						:key="id"
						:value="`${prefix}.gallery.${id}.image`"
						lazy
						:base="{width: 318}"
                        :sm="{width: 318}"
						:md="{width: 330}"
						:lg="{width: 360}"
						:xl="{width: 215}"
						:xxl="{width: 270}"
					/>
				</div>
				<cms-multi-section class="section-elements" :value="`${prefix}.section_content`" v-animate="`opacity`"/>
			</base-container>
		</div>
	</section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import BaseHeading from '../../components/molecules/headings/BaseHeading/BaseHeading.vue'
export default {
	components: {
		BaseHeading,
		CmsMultiSection
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		background () {
			const path = this.value.background.path

			return {
				src: path,
				breakpoints: {
					base: { width: 360, height: 650 },
					lg: { width: 825, height: 451 },
					xl: { width: 1920, height: 515 }
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    ::v-deep .link {
        text-transform: lowercase !important;
        text-decoration: underline !important;
    }
	padding: 10rem 0 0 0;

	@include media-breakpoint-up(xl) {
		padding: 16rem 0 0 0;
	}
	.image-wrapper {
		display: flex;
		margin-bottom: 2rem;
        justify-content: center;
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			margin-bottom: 0;
            justify-content: initial;
		}
		@include media-breakpoint-up(xl) {
			left: 0;
		}
		@include media-breakpoint-up(xxl) {
			gap: 1rem;
		}
		.image:nth-of-type(n + 2) {
			display: none;
			@include media-breakpoint-up(xl) {
				display: block;
			}
		}
        @media (min-width: 2500px) {
            left: 50%;
            transform: translate(-100%, -50%);
        }
        @include media-breakpoint-up(xl) {
            ::v-deep .base-image {
                width: auto;
            }
        }
	}
	.wrapper {
		display: flex;
		position: relative;
		padding: 4rem 0;
		background-repeat: no-repeat;
		background-size: cover;
		@include media-breakpoint-up(xl) {
			padding: 8rem 0;
		}
	}
	.section-elements {
		@include media-breakpoint-up(md) {
			margin-left: auto;
			width: 55%;
		}
		@include media-breakpoint-up(lg) {
			width: 60%;
		}
		@include media-breakpoint-up(xl) {
			width: 45%;
		}

		::v-deep .fs-lg {
			color: $secondary;
		}
	}
}

</style>
